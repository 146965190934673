import React, { useMemo } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { trpc } from '../../utils/trpc-client';
import { Campaign, Strategy } from 'shared/src/types';
import { useStore } from '../../store/store';
import { getStrategy } from '../../store/state-fns';
import { combineStrategy } from '../../store/strategy-combiner';
import { StrategyPage } from './strategy-page';
import { LineItemPage } from '../line-items/line-item-page';
import { MediaBuyPage } from '../media-buys/media-buy-page';
import { StrategyContextProvider } from '../line-items/strategy-context';

export function CampaignStrategyPage() {
  const { zohoCampaignId } = useParams<{ zohoCampaignId: string }>();
  const strategy = trpc.strategy.useQuery({ zoho_campaign_id: zohoCampaignId! });
  const campaign = trpc.campaign.useQuery({ zoho_campaign_id: zohoCampaignId! });

  if (strategy.isLoading || !strategy.data) {
    return <div>Loading...</div>;
  }
  return (
    <StrategyRoutes strategy={strategy.data} campaign={campaign?.data} refetch={strategy.refetch} />
  );
}

type StrategyRouteProps = {
  strategy: Strategy;
  campaign: Campaign | undefined;
  refetch: () => Promise<unknown>;
};

export function StrategyRoutes({ strategy, campaign, refetch }: StrategyRouteProps) {
  const storeStrategy = useStore(state => getStrategy(state, strategy.id));
  const combinedStrategy = useMemo(
    () => combineStrategy(strategy, storeStrategy),
    [strategy, storeStrategy]
  );

  return (
    <StrategyContextProvider strategy={combinedStrategy} campaign={campaign} refetch={refetch}>
      <Routes>
        <Route path="strategy/*" element={<StrategyPage />} />
        <Route path="strategy/lineitems/:lineItemId/*" element={<LineItemPage />} />
        <Route
          path="strategy/lineitems/:lineItemId/mediabuys/:mediaBuyId/*"
          element={<MediaBuyPage />}
        />
      </Routes>
    </StrategyContextProvider>
  );
}
