import { State } from './store';
import { CreateMediaBuyOptions, DraftStrategyChanges } from 'shared/src/types';
import { v4 as uuid } from 'uuid';
import { LineItem, LineItemChangeDraft, PartialLineItem } from 'shared/src/line-item-types';
import { CombinedLineItem } from './strategy-combiner';
import { tsOmit } from 'shared/src/object-utils';
import { cloneDeep, isEqual } from 'lodash';

const DEFAULT_STRATEGY = { line_items: {}, media_buys: {} };

// Functions in here operate on the state passed from the store
// This state is an immer object that allows direct mutations but these happen on a draft
// object and zustand takes care of publishing it and updating react components that
// depend on it

export function addLineItem(state: State, lineItem: PartialLineItem) {
  let strategy = state.campaigns[lineItem.strategy_id];
  if (!strategy) {
    state.campaigns[lineItem.strategy_id] = { line_items: {}, media_buys: {} };
    strategy = state.campaigns[lineItem.strategy_id];
  }
  const lineItemId = uuid();
  strategy.line_items[lineItemId] = {
    type: 'new',
    data: { ...lineItem, id: lineItemId }
  };
  const lineItemData = strategy.line_items[lineItemId].data;
  if (lineItemData.pacing_type === 'custom' && !lineItemData.pacing_details) {
    lineItemData.pacing_details = { blocks: [] };
  }
}

export function updateLineItem(state: State, update: PartialLineItem, original?: LineItem) {
  const { strategy_id } = update;
  if (!state.campaigns[strategy_id]) {
    state.campaigns[strategy_id] = { line_items: {}, media_buys: {} };
  }
  const strategy = state.campaigns[update.strategy_id];
  const lineItem = strategy.line_items[update.id];
  if (lineItem) {
    strategy.line_items[update.id].data = { ...lineItem.data, ...update };
  } else {
    strategy.line_items[update.id] = { type: 'update', data: { ...update } };
  }
  removeUnchangedValues(strategy.line_items, update.id, original);
}

export function removeLineItem(state: State, strategyId: string, lineItemId: string) {
  const strategy = state.campaigns[strategyId];
  if (!strategy) return;
  delete strategy.line_items[lineItemId];
}

export function resetChanges(state: State, strategyId: string) {
  state.campaigns[strategyId] = { line_items: {}, media_buys: {} };
}

export function getStrategy(state: State, strategyId: string): DraftStrategyChanges {
  return state.campaigns[strategyId] || DEFAULT_STRATEGY;
}

export function addMediaBuy(state: State, options: CreateMediaBuyOptions) {
  let strategy = state.campaigns[options.strategyId];
  if (!strategy) {
    state.campaigns[options.strategyId] = { line_items: {}, media_buys: {} };
    strategy = state.campaigns[options.strategyId];
  }
  const mediaBuyId = uuid();
  strategy.media_buys[mediaBuyId] = {
    type: 'new',
    data: {
      id: mediaBuyId,
      line_item_id: options.lineItemId,
      media_platform_id: options.platformId,
      name: options.name,
      start_date: options.startDate,
      end_date: options.endDate,
      budget: options.budget
    }
  };
}

export function removeMediaBuy(state: State, mediaBuyId: string) {
  Object.entries(state.campaigns).forEach(([_, value]) => {
    delete value.media_buys[mediaBuyId];
  });
}

export function duplicateLineItems(state: State, lineItems: CombinedLineItem[]) {
  if (lineItems.length === 0) return;
  const strategyId = lineItems[0].strategy_id;
  let strategy = state.campaigns[strategyId];
  if (!strategy) {
    state.campaigns[strategyId] = { line_items: {}, media_buys: {} };
    strategy = state.campaigns[strategyId];
  }
  for (const lineItem of lineItems) {
    const lineItemId = uuid();
    strategy.line_items[lineItemId] = {
      type: 'new',
      data: {
        ...cloneDeep(tsOmit(lineItem, ['id', 'state', 'media_buys'])),
        id: lineItemId
      }
    };
  }
}

function removeUnchangedValues(
  lineItems: Record<string, LineItemChangeDraft>,
  id: string,
  original?: LineItem
) {
  const lineItem = lineItems[id];
  if (!lineItem || !original) return;
  const update = lineItem.data;
  for (const key in tsOmit(update, ['id', 'strategy_id'])) {
    if (isEqual(update[key as keyof PartialLineItem], original[key as keyof PartialLineItem])) {
      delete update[key as keyof PartialLineItem];
    }
  }
  if (Object.keys(tsOmit(update, ['id', 'strategy_id'])).length === 0) {
    delete lineItems[id];
  }
}
